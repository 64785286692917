import {
  Col,

  Card,
  Row,

} from "react-materialize";
import "materialize-css";
import React, { useEffect } from "react";

import wodkan from "./svg/wodkan.svg"
import gaz from "./svg/gaz.svg"
import co from "./svg/co.svg"
import Aos from "aos";
import "aos/dist/aos.css";

function Other() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <Row>
      <Col  s={12} m={6} l={5}  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2 scope"
      >
        <Card className="scope white-text blue darken-2 bold">
          <div className="center"> 
          <img  src={wodkan} width="30%"/>
              <h5 className="bold">Instalacje wodno-kanalizacyjne:</h5>
              
              </div>
              <br />
              <li> usuwanie awarii instalacyjnych</li>
              <li> udrażnianie odpływów kanalizacyjnych</li>
              <li>
                naprawa spłuczek, kompaktów, systemy podtynkowe GEBERIT, SANIT,
                itp.
              </li>
              <li> montaż i naprawa baterii, zaworów</li>
              <li> montaż zmywarek, pralek</li>
              <li> montaż umywalek, kabin prysznicowych,</li>
              WC, wanien
              <li> montaż bojlerów, podgrzewaczy wody.</li>
              <li> Wykonujemy kompleksowo instalacje wodno-kanalizacyjne.</li>
              <li>Przeglady instalacji wod-kan</li>
              <li>okresowe przeglądy hydroforni</li>
              <li>Wymiana elementów instalacji, wodomierzy</li>
              <li>próby ciśnieniowe</li>
        </Card>
      </div>
      </Col>
      <Col  s={12} m={6} l={5} push="l2" className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2500"
        className="hoverable z-depth-2 scope"
      >
        <Card className="scope white-text orange bold">
        <div className="center"> 
          <img  src={gaz} width="30%"/>
              <h5 className="bold ">Instalacje gazowe:</h5>
              
              </div>
              <br />
              <li> usuwanie awarii instalacyjnych</li>
             <li>roczne przeglądy</li>
             <li>próby szczelności</li>
             <li>montaż kuchenek gazowych i innych</li>
             <li>wymiana boilerów</li>
             <li>montaż piecy gazowych wszelkiego typu</li>
        </Card>
      </div>
      </Col>
      <Row/>
      <Col  s={12} m={6} l={5} push="" className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="3000"
        className="hoverable z-depth-2 scope"
      >
        <Card className="scope  orange accent-4 white-text bold">
        <div className="center"> 
          <img  src={co} width="30%"/>
          <h5 className="bold">Instalacje centralnego ogrzewania:</h5>
              
              </div>
             
              <br />
              <li> usuwanie awarii instalacyjnych</li>
              <li>montaż zasobników ciepłej wody</li>
              <li>montaż piecy gazowych wszelkiego typu</li>
              <li>wymiana boilerów</li>
              <li>ogrzewanie podłogowe</li>
              <li>grzejniki</li>

        </Card>
      </div>
      </Col>
      <Col  s={12} m={6} l={5} push="l2" className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="3000"
        className="hoverable z-depth-2 scope"
      >
        <Card className="scope grey darken-3 white-text">
              <h5>Usługi wykończeniowe</h5>
              <br />
             <li>biały montaż</li>
             <li>montaż sprzetów np. zmywarek, pralek itp.</li>
             <li>montaż toalet, wanien, pryszniców itp.</li>
             <li>montaż kuchenek</li>
        </Card>
      </div>
      </Col>
     
      <Col  s={12} m={6} l={5} push="l2"  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2 scope"
      >
        <Card className="scope grey darken-3 white-text">
              <h5>Roczne przęglody</h5>
              <br />
           <li>instalacji gazowych</li>
           <li>próby ciśnieniowe szczelności wod-kan, gaz</li>
           
        </Card>
      </div>
      </Col>
      <Row/>
      <Col  s={12} m={6} l={5}  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2"
      >
        <Card className="scope red darken-3 white-text">
              <h5>Awarie</h5>
              <br />
              <li>szybki dojazd na terenie Krakowa i okolic</li>
              <li>awarie centralnego ogrzewania </li>
              <li>wod-kan, przecieki, zalania</li>
          
        </Card>
      </div>
      </Col>
      <Col  s={12} m={6} l={5}  push="l2" className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2"
      >
        <Card className="scope  green darken-3 white-text">
              <h5>Przyłącza</h5>
              <br />
              <li> wykonywanie przyłączy wod-kan</li>
             
        </Card>
      </div>
      </Col>
    
    </Row>
  );
}

export default Other;
