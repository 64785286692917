import "leaflet/dist/leaflet.css";
import "materialize-css";
import React from "react";
import "react-leaflet";
import { CardPanel, Col, Row, Toast } from "react-materialize";
import Divider from "react-materialize/lib/Divider";
import emailjs from "emailjs-com";
import Iframe from "react-iframe";
function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_g3d8nti",
      "template_80g0god",
      e.target,
      "Nyg9Z6QIOjJri3Vgr"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

class Contact extends React.Component {
  constructor() {
    super();

    this.state = {
      lang: localStorage.getItem("lang"),
    };
  }

  render() {
    return (
      <div>
        <Row>
          <Row />
          <Col m={4} s={12} offset="m1">
            <CardPanel className="white z-depth-2">
              <h5 className="center">Formularz Kontaktowy</h5>
              <Row></Row>
              <Divider />
              <Row></Row>
              <div className="center ">
                <Row>
                  <form onSubmit={sendEmail}>
                  <label className="">
                      <h5 className="black-text">Twój Email</h5>
                    </label>
                    <input
                      className="black-text"
                      type="email"
                      name="user_email"
                    />
                    <label className="">
                      <h5 className="black-text">Twoja Wiadomość</h5>
                    </label>
                    <textarea className="black-text" name="message" />{" "}
                    
                    <Toast
                      className="white"
                      options={{
                        classes: "center",
                        html: "Wiadomość wysłana, dziękujemy !",
                      }}
                    >
                      <input
                        className="btn blue"
                        type="submit"
                        value="Wyślij wiadomość"
                      />
                    </Toast>
                  </form>
                </Row>
              </div>
            </CardPanel>
          </Col>

        
          <Col m={6} s={12}>
           
          
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.158926247468!2d19.915160215718164!3d50.083311279426454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165a4ce3499591%3A0x1ba5fa39527120e6!2sPiotra%20Stachiewicza%2017%2C%2031-303%20Krak%C3%B3w!5e0!3m2!1spl!2spl!4v1649935250281!5m2!1spl!2spl"
              
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                width="450px"
                height="450px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
         
          </Col>
        </Row>
      </div>
    );
  }
}
export default Contact;
