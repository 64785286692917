import {
  Col,

  Card,
  Row,

} from "react-materialize";

import "materialize-css";
import React, { useEffect } from "react";


import Aos from "aos";
import "aos/dist/aos.css";

function Other() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
   <Row>
     <h3>Zadowoleni klienci</h3>
     <br/>
     <Row>
      <Col  s={12} m={6} l={3}  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2 scope"
      >
         <Card className="scope">
              <h5>Iwona
              <br /><br />
              ❝ Szybki dojazd, miły Pan, parlka już nie cieknie. ❝
        </h5>
        </Card>
      </div>
      </Col>
      <Col  s={12} m={6} l={3}  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="2500"
        className="hoverable z-depth-2 scope"
      >
         <Card className="scope">
         <h5>Kuba
              <br /><br />
              ❝ Wszystko super ❝
        </h5>
        </Card>
      </div>
      </Col>
      <Col  s={12} m={6} l={3}  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="3000"
        className="hoverable z-depth-2 scope"
      >
        
        <Card className="scope">
        <h5>Robert
              <br /><br />
              ❝ Profesjonalna usługa, dobry kontakt. ❝
        </h5>
        </Card>
       
      </div>
      </Col>
      <Col  s={12} m={6} l={3}  className="left-align">
      <div
        data-aos="zoom-in"
        data-aos-duration="3000"
        className="hoverable z-depth-2 scope"
      >
        <Card className="scope">
        <h5>Maciej
              <br /><br />
              ❝ Poprawił błędy po innym "fachowcu" ❝
        </h5>
        </Card>
      </div>
      </Col>
     <Row/>
   </Row>
   <Row/><Row/><Row/><Row/>
 
   
   </Row>
  );
}

export default Other;
