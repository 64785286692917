import {
  Col,

  Card,
  Row,

} from "react-materialize";

import "materialize-css";
import React, { useEffect } from "react";

import portret from "./svg/portret.webp"
import Aos from "aos";
import "aos/dist/aos.css";

function Other() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <Row>
      <div
        data-aos="zoom-out"
        data-aos-duration="2000"
        className=""
      > 
      <Card className="blue darken-3 white-text bold">
      <h3>Doświadczenie</h3>
     </Card></div>
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2"
      >
        <Card className="scope">
          <Row>
            
              <Col s={6} m={2} l={2}>
                
<img className="portret" src={portret} width="100%"/>
            </Col>
            
            <Col s={12} m={8} l={8} className="left-align">
              <h3>O mnie</h3>
              <br />
              <div className="omnie">
              Bogusław Wnęk, pracuję w zawodzie od 40 lat, prowadzę własną
              działalność od 1996 roku. Mam doświadczenie w małych oraz dużych
              inwestycjach, począwszy od mieszkań i domów jednorodzinnych,
              budynkach użyteczności publicznej kończąc na budynkach
              wysokościowych. 
              <br/>Korzystam tylko z profesjonalnego sprzętu. Nie
              pozostawiam po sobie bałagamu. Gwarantuję wysoką jakość usług.</div>
            </Col>
            
          </Row>
        </Card>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        className="hoverable z-depth-2"
      >
        <Card className="scope">
          <Row>
            <Col s={12} m={8} push="m2" className="left-align">
              <h3>Uprawnienia</h3>
              <br />
              <div className="omnie">
              ✔ do wykonywania instalacji i kierowania pracami wod-kan w zakresie pełnym<br/>
              ✔ do wykonywania instalacji i kierowania pracami gaz w zakresie wewnętrznych instalacji gazowych<br/>
              ✔ do wykonywania instalacji i kierowania pracami centralnego ogrzewania w zakresie instalacji wewnętrznych<br/>
              ✔ uprawnienia energetyczne wzakresie dozoru i eksploatacji<br/>
              ✔ uprawnienia elektryczne do 1 kW
              </div>
           
            </Col>
            <Col s={12} m={6}></Col>
          </Row>
        </Card>
      </div>
      
    </Row>
  );
}

export default Other;
